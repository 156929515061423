import { route } from 'common/api';

export const update = ( { user_id, provider_url, provider_type } ) => route( `/onboarding/user/${ user_id }`, {
	method: 'PUT',
	body: JSON.stringify( {
		provider_url,
		provider_type,
	} ),
} );

export const getDomains = ( license_key ) => route( `/onboarding/domains/${ license_key }/` );
