/**
 * @module
 * @description Activate/Deactivate
 */
import * as STATUS from 'apps/constants/message-status';
import * as tools from 'utils/tools';
import { activateMessage } from './service';

const el = {
	activateButtons: tools.getNodes( 'timeline__message-menu-button--activate', true ),
};

/**
 * @function onClick
 * @description Handle click event
 * @async
 * @param {Event} e
 */
const onClick = async( e ) => {
	const message = tools.closest( e.target, '.timeline__accordion-message' );

	if ( ! message ) {
		return;
	}

	const data = JSON.parse( message.dataset.json );
	const id = Number( data.id );
	const sourceId = Number( message.dataset.sourceId );
	const isActive = data.active;
	const payload = {
		...data,
		status: isActive ? STATUS.INACTIVE : STATUS.ACTIVE,
		active: isActive ? 0 : 1,
	};

	const response = await activateMessage( id, sourceId, payload );
	if ( response.status === 200 ) {
		window.location.reload();
	}
};

/**
 * @function bindEvents
 * @description Bind event handlers to listeners
 */
const bindEvents = () => {
	if ( ! el.activateButtons ) {
		return;
	}

	el.activateButtons.forEach( button => (
		button.addEventListener( 'click', onClick )
	) );
};

/**
 * @function init
 * @description Kick off this modules functions
 */
const init = () => {
	bindEvents();
};

export default init;
