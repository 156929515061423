import campaign from './campaign';
import confirmation from './confirmation';
import del from './delete';
import duplicate from './duplicate';
import preview from './preview';
import ui from './ui';
import edit from './edit';

const init = () => {
	campaign();
	confirmation();
	del();
	duplicate();
	preview();
	ui();
	edit();
};

export default init;
