/**
 * @module
 * @description Delete Service
 */
import axios from 'axios';
import { MESSAGES_STANDARD_ENDPOINT, MESSAGES_TRIGGER_ENDPOINT } from 'config/settings';

export const deleteMessage = async( id, sourceId ) => {
	const base = sourceId ? `${ MESSAGES_TRIGGER_ENDPOINT }/${ sourceId }/${ id }` : `${ MESSAGES_STANDARD_ENDPOINT }/${ id }`;
	return await axios.delete( `${ base }?redirect=timeline` );
};
