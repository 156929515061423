/**
 * @module
 * @description Delete
 */
import * as tools from 'utils/tools';
import { deleteShortcut } from './service';

const DELETE = 'delete';
const REVERT = 'revert';

const el = {
	deleteCardDescription: tools.getNodes( 'modal__delete-card-description' )[ 0 ],
	deleteCardImage: tools.getNodes( 'modal__delete-card-img' )[ 0 ],
	deleteCardTitle: tools.getNodes( 'modal__delete-card-title' )[ 0 ],
	deleteButton: tools.getNodes( 'modal__delete-button' )[ 0 ],
	deleteMenuButtons: tools.getNodes( 'shortcut__menu-button--delete-shortcut', true ),
	deleteSelect: tools.getNodes( 'modal__delete-select' )[ 0 ],
	revertCardDescription: tools.getNodes( 'modal__revert-card-description' )[ 0 ],
	revertCardImage: tools.getNodes( 'modal__revert-card-img' )[ 0 ],
	revertCardTitle: tools.getNodes( 'modal__revert-card-title' )[ 0 ],
	revertButton: tools.getNodes( 'modal__revert-button' )[ 0 ],
	revertMenuButtons: tools.getNodes( 'shortcut__menu-button--revert-shortcut', true ),
	revertSelect: tools.getNodes( 'modal__revert-select' )[ 0 ],
};

const state = {
	id: 0,
};

/**
 * @function onMenuButtonClick
 * @description Handles delete menu button click event
 * @param {Event} e
 */
const onMenuButtonClick = ( action ) => ( e ) => {
	const shortcut = tools.closest( e.target, '.shortcut' );
	state.id = Number( shortcut.dataset.id );
	el[ `${ action }CardImage` ].style = tools.getNodes( '.shortcut__img', false, shortcut, true )[ 0 ].style.cssText;
	el[ `${ action }CardTitle` ].textContent = tools.getNodes( '.shortcut__title', false, shortcut, true )[ 0 ].textContent;
	el[ `${ action }CardDescription` ].textContent = tools.getNodes( '.shortcut__description', false, shortcut, true )[ 0 ].textContent;
};

/**
 * @function onConfirmClick
 * @description Handles confirm button click event
 * @async
 */
const onConfirmClick = async() => {
	const response = await deleteShortcut( state.id );
	if ( response.status === 200 && response.data.redirect ) {
		window.location = response.data.redirect;
	}
};

/**
 * @function onSelectChange
 * @description Handles select change event
 * @param {Event} e
 */
const onSelectChange = ( action ) => ( e ) => {
	e.target.value === 'yes'
		? el[ `${ action }Button` ].removeAttribute( 'disabled' )
		: el[ `${ action }Button` ].setAttribute( 'disabled', true );
};

/**
 * @function bindEvents
 * @description Bind event handlers to listeners
 */
const bindEvents = () => {
	el.deleteMenuButtons.forEach( button => (
		button.addEventListener( 'click', onMenuButtonClick( DELETE ) )
	) );
	el.deleteButton.addEventListener( 'click', onConfirmClick );
	el.deleteSelect.addEventListener( 'change', onSelectChange( DELETE ) );
	el.revertMenuButtons.forEach( button => (
		button.addEventListener( 'click', onMenuButtonClick( REVERT ) )
	) );
	el.revertButton.addEventListener( 'click', onConfirmClick );
	el.revertSelect.addEventListener( 'change', onSelectChange( REVERT ) );
};

/**
 * @function init
 * @description Kick off this modules functions
 */
const init = () => {
	if ( el.deleteButton ) {
		bindEvents();
	}
};

export default init;
