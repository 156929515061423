/**
 * @module
 * @description Duplicate Service
 */

import axios from 'axios';
import { DUPLICATE_SHORTCUTS_ENDPOINT } from 'config/settings';

export async function duplicateShortcut( id, type, action ) {
	return await axios.post( `${ DUPLICATE_SHORTCUTS_ENDPOINT }?redirect=self`, {
		id,
		type,
		action,
	} );
}
