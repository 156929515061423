/**
 * @module
 * @description Onboarding Flow
 */

import delegate from 'delegate';
import * as tools from 'utils/tools';
import ui from './ui';
import services from './services';

const el = {
	container: tools.getNodes( 'onboarding' )[ 0 ],
	license: tools.getNodes( 'license-input' )[ 0 ],
	licenseFeedback: tools.getNodes( 'license-input-feedback' )[ 0 ],
	licenseButton: tools.getNodes( 'next-profile' )[ 0 ],
	sync: tools.getNodes( 'section-sync' )[ 0 ],
	profile: tools.getNodes( 'section-profile' )[ 0 ],
	websites: tools.getNodes( 'section-websites' )[ 0 ],
	review: tools.getNodes( 'section-review' )[ 0 ],
	firstName: tools.getNodes( 'user-first-name' )[ 0 ],
	lastName: tools.getNodes( 'user-last-name' )[ 0 ],
	email: tools.getNodes( 'user-email' )[ 0 ],
	emailError: tools.getNodes( 'user-email-error' )[ 0 ],
	websiteListing: tools.getNodes( 'website-listing' )[ 0 ],
	nextSync: tools.getNodes( 'next-sync' )[ 0 ],
	loading: tools.getNodes( 'section-loading' )[ 0 ],
};

const userData = {
	user: '',
	installs: '',
	selectedSite: '',
	returnedUser: '',
};

/**
 * @function clearDisable
 * @description Allow license to be submitted
 */
const clearDisable = ( e ) => {
	if ( e.target.value ) {
		el.licenseButton.removeAttribute( 'disabled' );
	} else {
		el.licenseButton.setAttribute( 'disabled', 'disabled' );
	}
};

/**
 * @function goToProfile
 * @description change content of page to show profile
 */

const goToProfile = () => {
	el.sync.style.display = 'none';
	el.profile.style.display = 'block';

	el.firstName.value = userData.user.first_name;
	el.lastName.value = userData.user.last_name;
	el.email.value = userData.user.email;

	ui.sidebar.next();
};

/**
 * @function validateLicense
 * @description Check the license
 */

const validateLicense = () => {
	const licenseNumber = el.license.value;

	ui.loader.show();

	services.provider.getDomains( licenseNumber )
		.then( ( { user, installs } ) => {
			// save data for future steps
			userData.user = user;
			userData.installs = installs;

			// add success state to input
			el.license.classList.remove( 'license__input--fail' );
			el.license.classList.add( 'license__input--success' );

			goToProfile();
		} )
		.catch( ( error ) => {
			el.licenseFeedback.textContent = 'Invalid License Input';
			if ( error.response && error.response.data && 'undefined' !== typeof error.response.data.error ) {
				el.licenseFeedback.textContent = error.response.data.error;
			} else {
				el.licenseFeedback.textContent = error.message;
			}

			el.license.classList.add( 'license__input--fail' );
		} )
		.then( () => {
			ui.loader.hide();
		} );
};

/**
 * @function goToWebsites
 * @description change content of page to show website selection
 */

const goToWebsites = () => {
	location.href = '/welcome/website';
};

/**
 * @function submitProfile
 * @description change content of page to show website selection
 */

const submitProfile = () => {
	const email = document.querySelector( '[data-js="user-email"]' ).value;
	const password = document.querySelector( '[data-js="user-profile__form-password-input"]' ).value;
	const source_id = userData.user.source_id;
	const first_name = document.querySelector( '[data-js="user-first-name"]' ).value;
	const last_name = document.querySelector( '[data-js="user-last-name"]' ).value;
	const license_key = el.license.value;
	const company = '';
	const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

	// if empty fields
	if ( first_name === '' || last_name === '' || email === '' ) {
		if ( first_name === '' ) {
			el.firstName.classList.add( 'form-control-error' );
		}

		if ( last_name === '' ) {
			el.lastName.classList.add( 'form-control-error' );
		}

		if ( email === '' ) {
			el.emailError.innerHTML = 'Email is required';
			el.email.classList.add( 'form-control-error' );
		}

		return;
	}

	ui.loader.show();

	services.auth.addUser( {
		email,
		password,
		source_id,
		first_name,
		last_name,
		license_key,
		company,
		timezone,
	} )
		.then( ( { user_id } ) => {
			userData.returnedUser = user_id;
			goToWebsites();
		} )
		.catch( ( error ) => {
			// if email is already in use
			el.email.classList.add( 'form-control-error' );
			if ( error.response && error.response.data && error.response.data.error === 'Email already exists.' ) {
				el.emailError.innerHTML = error.response.data.error;
				el.email.classList.add( 'form-control-error' );
			} else {
				el.emailError.innerHTML = error.message;
			}
		} )
		.then( () => {
			ui.loader.hide();
		} );
};

/**
 * @function clearError
 * @description on keyup, if the error was shown already, remove it
 */

const clearError = ( e ) => {
	if ( e.target.classList.contains( 'form-control-error' ) ) {
		e.target.classList.remove( 'form-control-error' );
	}
};

/**
 * @function bindEvents
 * @description Bind the events for this module.
 */

const bindEvents = () => {
	delegate( document, '[data-js="license-input"]', 'keyup', clearDisable );
	delegate( document, '[data-js="next-profile"]', 'click', validateLicense );
	delegate( document, '[data-js="user-profile__form-button"]', 'click', submitProfile );
	delegate( document, '[data-js="user-email"]', 'keyup', clearError );
	delegate( document, '[data-js="user-first-name"]', 'keyup', clearError );
	delegate( document, '[data-js="user-last-name"]', 'keyup', clearError );
};

/**
 * @function init
 * @description Kick off this modules functions
 */

const onboarding = () => {
	if ( ! el.container ) {
		return;
	}
	bindEvents();
};

export default onboarding;
