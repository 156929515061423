/*global fetch*/

import reduce from 'lodash/reduce';
import isFunction from 'lodash/isFunction';
import omit from 'lodash/omit';
import isEmpty from 'lodash/isEmpty';
import toFormData from 'object-to-formdata';
import { stringify } from 'query-string';
import stripTags from 'voca/strip_tags';
import trim from 'voca/trim';
import unescapeHTML from 'voca/unescape_html';
import { BASE_URL } from 'config/settings';
import trimStart from 'lodash/trimStart';
import trimEnd from 'lodash/trimEnd';

const PATH_MAP = {
	save_message: 'save_message',
	delete_message: 'delete_message',
	preview_message: 'preview_message',
	upload_image: 'upload_image',
	save_settings: 'save_settings',
	save_shortcut: 'save_shortcut',
	shortcuts: 'shortcuts',
};

//
// ─── REST API REQUEST ───────────────────────────────────────────────────────────
//
export const getOptions = ( options ) => {
	const newOptions = omit( options, [ 'body', 'headers' ] );
	const parseBody = options.body ? options.body : {};

	newOptions.body = JSON.stringify( parseBody );
	newOptions.headers = getTokenHeader( options );
	newOptions.mode = 'same-origin';
	newOptions.credentials = 'same-origin';

	return newOptions;
};

export const getQueryString = ( params ) => {
	const newParams = reduce( params, ( mem, param, key ) => (
		param ? { ...mem, [ key ]: param } : mem
	), {} );

	return ( newParams && ! isEmpty( newParams ) )
		? stringify( newParams, { arrayFormat: 'bracket' } )
		: '';
};

export const getUrl = ( path, queryString ) => {
	return queryString ? `${ path }?${ queryString }` : path;
};

export const rest = ( path, options, method ) => {
	const defaultOptions = { ...options, method };
	const newOptions = getOptions( defaultOptions );
	const queryString = getQueryString( defaultOptions.params );
	const url = getUrl( path, queryString );
	return request( url, newOptions );
};

export const get = ( path, options ) => rest( path, options, 'GET' );

export const post = ( path, options ) => rest( path, options, 'POST' );

export const put = ( path, options ) => rest( path, options, 'PUT' );

export const del = ( path, options ) => rest( path, options, 'DELETE' );

//
// ─── GENERIC FETCH ──────────────────────────────────────────────────────────────
//

// reasonably generic post (or get)
// pathKey must be one of PATH_MAP
// if pathKey references a template function,
// then options should have a restParms key, which functions as a dictionary for the url template
// can also have a params key, which is a dict of queryParams
// you can also add other valid fetch options, to options (such as method and body)
export function retrieve( host, pathKey, options ) {
	const defaultOptions = { method: 'GET', ...options };
	const newOptions = omit( defaultOptions, 'body' );

	// determine full path, using template if needed
	if ( ! pathKey || ! PATH_MAP[ pathKey ] ) {
		throw new Error( `Unknown pathKey: ${ pathKey }` );
	}
	let path = PATH_MAP[ pathKey ];
	if ( isFunction( path ) ) {
		path = PATH_MAP[ pathKey ]( { ...options.body, ...newOptions.restParms } );
	}
	let url = `${ host }${ path }`;
	// encode as multipart/form-data
	if ( newOptions.method !== 'GET' && newOptions.method !== 'HEAD' ) {
		const parseBody = options.body ? options.body : {};
		newOptions.body = toFormData( parseBody );
	}

	if ( newOptions.json ) {
		newOptions.body = JSON.stringify( newOptions.json );
	}

	// any params destined to become query parameters
	const params = reduce( newOptions.params, ( mem, param, key ) => {
		const newMem = { ...mem };
		newMem[ key ] = param;
		return param ? newMem : mem;
	}, {} );
	if ( params && ! isEmpty( params ) ) {
		const p = stringify( params, { arrayFormat: 'bracket' } );
		url = `${ url }?${ p }`;
	}
	return request( url, newOptions );
}

/**
 * Create a new Ajax Request looking and using the X-CSRF-TOKEN into the headers request
 * so the request can pass the middleware validation of the token in routes.
 *
 * @param {string} url
 * @param {object} options
 * @returns {*}
 */
export function route( url, options = {} ) {
	return simpleRequest(
		url,
		{
			credentials: 'same-origin',
			...options,
			headers: getTokenHeader( options ),
		}
	);
}

/**
 * Get the token from the <meta name="X-CSRF-TOKEN" content="">
 *
 * @param {object} options
 * @returns {Headers}
 */
export function getTokenHeader( options ) {
	// If the request already has a token on it move on.
	const headers = options.headers && options.headers instanceof Headers
		? options.headers
		: new Headers();

	const TOKEN_NAME = 'X-CSRF-TOKEN';

	// Make sure Laravel BE  is able to detect Ajax request, but
	// don't add these headers if we're dealing with file upload
	if ( options.body && ! ( options.body instanceof FormData ) ) {
		headers.append( 'Content-Type', 'application/json' );
		headers.append( 'Accept', 'application/json, text-plain, */*' );
		headers.append( 'X-Requested-With', 'XMLHttpRequest' );
	}

	if ( headers.has( TOKEN_NAME ) ) {
		return headers;
	}

	const tokenEl = document.querySelector( 'meta[name="csrf-token"]' );
	const { content = '' } = tokenEl || {};

	if ( ! tokenEl ) {
		throw new Error( 'A token must be present on the document.' );
	} else if ( ! content ) {
		throw new Error( 'The Token content must not be empty' );
	}

	headers.append( TOKEN_NAME, content );

	return headers;
}

export function request( url, options ) {
	const start = Date.now();
	console.info( `Fetching url: ${ url }` );
	console.info( 'with options', options );
	// do the fetch
	return fetch( url, { ...options } ).then( ( response ) => {
		console.info( response );
		return response.text().then( ( text ) => {
			let data = {};
			try {
				data = JSON.parse( text );
				const time = Date.now() - start;
				console.info( `Data in ${ time }ms:`, data );
			} catch ( error ) {
				const message = trim( stripTags( unescapeHTML( text ) ) );
				const err = new Error( `Invalid server response. ${ message }` );
				err.detail = {
					url,
					data,
					message,
					status: response.status,
					error,
				};
				throw err;
			}

			if ( response.ok ) {
				return {
					data,
					status: response.status,
				};
			}

			const message = data.error ? data.error.trim() : '';
			const err = new Error( `Unknown server response. ${ message }` );
			const { errors = [] } = data || {};
			err.detail = {
				url,
				data,
				message,
				status: response.status,
				errors,
			};
			throw err;
		} );
	} ).catch( ( error ) => {
		if ( error.detail ) {
			throw error;
		}

		const message = error.detail;
		const err = new Error( `Unknown server response. ${ message }` );
		err.detail = {
			url,
			data: error,
			message,
			status: error.status,
		};

		throw err;
	} );
}

class PromoterError extends Error {
	constructor( details, ...params ) {
		// Pass remaining arguments (including vendor specific ones) to parent constructor
		super( ...params );

		// Maintains proper stack trace for where our error was thrown (only available on V8)
		if ( Error.captureStackTrace ) {
			Error.captureStackTrace( this, PromoterError );
		}

		this.name = 'PromoterError';
		this.details = details;
	}
}

export function simpleRequest( url, options ) {
	return fetch( `${ trimEnd( BASE_URL, '/' ) }/${ trimStart( url, '/' ) }`, { ...options } )
		.then( ( response ) => {
			// No content no need to parse JSON
			if ( response.status === 204 ) {
				return {};
			}

			return response.json()
				.then( ( data ) => {
					if ( ! response.ok ) {
						const { message = 'Invalid response type' } = data || {};
						const errorMessage = trim( stripTags( unescapeHTML( message ) ) );

						requestError( errorMessage, {
							data,
							url,
							status: response.status,
						} );
					}
					return data;
				} );
		} ).catch( ( error ) => {
			if ( error instanceof PromoterError ) {
				throw error;
			} else {
				requestError( `Unknown server response. ${ error.detail }`, {
					url,
					data: error.detail,
					status: error.status,
				} );
			}
		} );
}

export function requestError( message, detail ) {
	throw new PromoterError( detail, message );
}
