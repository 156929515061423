const setCookie = ( name, value, length ) => {
	const d = new Date();
	const expireDate = new Date( d.setFullYear( d.getFullYear() + length ) );

	document.cookie = `${ name }=${ value };path=/;expires=${ expireDate }.toUTCString()`;
};

const getCookie = ( name ) => {
	const regex = new RegExp( `(^| )${ name }=([^;]+)` );
	const match = document.cookie.match( regex );

	if ( match ) {
		return match[ 2 ];
	}
	return false;
};

export { setCookie, getCookie };
