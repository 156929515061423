
/**
 * @module
 * @description Simple Validation plugin
 */

import delegate from 'delegate';
import * as tools from '../utils/tools';
import * as PasswordValidator from 'password-validator';

const el = {
	container: tools.getNodes( '#setPassword', true, document, true )[ 0 ],
	confirmPassword: tools.getNodes( 'user-profile__form-confirm-password-input' )[ 0 ],
	input: tools.getNodes( 'user-profile__form-password-input' )[ 0 ],
	inputGroup: tools.getNodes( 'user-profile__form-password-group' )[ 0 ],
	button: tools.getNodes( 'user-profile__form-button' )[ 0 ],
	validateItems: tools.getNodes( '[data-validation]', true, document, true ),
	confirmContain: tools.getNodes( 'pass-confirm-group' )[ 0 ],
	validPass: tools.getNodes( 'pass_valid_password' )[ 0 ],
};

const schema = new PasswordValidator();

/**
 * @function initSchema
 * @description kick off validation requirements.
 */

const initSchema = () => {
	schema
		.is().min( 8 )
		.has().lowercase()
		.has().uppercase()
		.has().digits()
		.has().symbols();
};

/**
 * @function buttonActivate
 * @description un-disable button
 */

const disableState = ( inputValue ) => {
	if ( ! el.confirmContain ) {
		// Activate button if all validation passes
		if ( schema.validate( inputValue ) === true ) {
			el.button.disabled = false;
			el.button.classList.remove( 'btn--disabled' );
		} else {
			el.button.disabled = true;
			el.button.classList.add( 'btn--disabled' );
		}
	}
};

/**
 * @function validateInput
 * @description Set the password validation schema
 */

const validateInput = () => {
	// Set value of input
	const inputValue = el.input.value;
	// Validate the input value
	schema.validate( inputValue );
	// Get array of validation failure properties
	const validationOptions = schema.validate( inputValue, { list: true } );

	el.validateItems.forEach( item => {
		const validValue = item.getAttribute( 'data-validation' );

		if ( validationOptions.includes( validValue ) ) {
			item.classList.add( 'disabled' );
		} else {
			item.classList.remove( 'disabled' );
		}
	} );

	if ( schema.validate( inputValue ) === true ) {
		el.inputGroup.classList.add( 'input--valid' );
		// if profile page
		if ( el.confirmContain ) {
			el.validPass.value = 'true';
		}
	} else {
		el.inputGroup.classList.remove( 'input--valid' );
		if ( el.confirmContain ) {
			el.validPass.value = 'false';
		}
	}

	disableState( inputValue );
};

/**
 * @function validateConfirmPassword
 * @description Check confirm password field matches password field
 */

const validateConfirmPassword = () => {
	// Set value of input
	const inputValue = el.input.value;
	const confirmInputValue = el.confirmPassword.value;

	// Activate button if all validation passes
	if ( inputValue !== confirmInputValue ) {
		el.confirmContain.classList.remove( 'input--valid' );
	} else {
		el.confirmContain.classList.add( 'input--valid' );
	}
};

/**
 * @function bindEvents
 * @description Bind the events for this module.
 */

const bindEvents = () => {
	el.input.addEventListener( 'keyup', validateInput );
	delegate( document, '[data-js="user-profile__form-confirm-password-input"]', 'keyup', validateConfirmPassword );
};

/**
 * @function init
 * @description Kick off this modules functions
 */

const validation = () => {
	if ( ! el.container ) {
		return;
	}

	initSchema();

	validateInput();

	bindEvents();
};

export default validation;
