/**
 * @module
 * @description Onboarding Flow
 */

import delegate from 'delegate';
import ClipboardJS from 'clipboard';
const IMask = window.IMask;

/**
 * @function setupFromEmailDomainMask
 * @description Configure the mask for the "from email" domain field.
 */
const setupFromEmailDomainMask = () => {
	document.querySelectorAll( '[data-js="domain-mask"]' ).forEach( ( element ) => {
		const domainValue = element.getAttribute( 'data-domain' );
		const domainEscapedValue = domainValue.split( '' ).join( '\\' );

		element.value = element.value.replace( '@' + domainValue, '' );
		if ( element.value === '' ) {
			element.value = 'promoter';
		}

		const maskOptions = {
			mask: `num@${ domainEscapedValue }`,
			lazy: false,
			blocks: {
				num: {
					mask: String,
					expose: true,
					max: 200,
				},
			},
		};

		const mask = IMask( element, maskOptions );
		const hiddenInput = document.createElement( 'input' );
		const container = element.closest( '.form-control-numbered-section' );
		const saveButton = container.querySelector( '[value="update_from_email"]' );

		hiddenInput.setAttribute( 'type', 'hidden' );
		hiddenInput.setAttribute( 'name', element.getAttribute( 'name' ) );
		hiddenInput.value = mask.typedValue.replace( '@' + domainValue, '' );

		hiddenInput.addEventListener( 'change', () => {
			saveButton.removeAttribute( 'disabled' );
		} );

		element.after( hiddenInput );
		element.removeAttribute( 'name' );

		mask.on( 'complete', () => {
			if ( mask.typedValue === 'promoter' ) {
				hiddenInput.value = '';
				return;
			}

			hiddenInput.value = mask.typedValue.replace( '@' + domainValue, '' );
			hiddenInput.dispatchEvent( new Event( 'change', { bubbles: true } ) );
		} );
	} );
};

/**
 * @function submitDeleteButton
 * @description When clicking the delete button on the mail delivery page, we need to add a hidden input to the form with the action and submit the form.
 * @param {Event} event
 */
const submitDeleteButton = ( event ) => {
	const form = document.querySelector( '#mailDeliveryForm' );
	let element = event.target;
	if ( element.tagName !== 'button' ) {
		element = element.closest( 'button' );
	}

	const hiddenInput = document.createElement( 'input' );
	hiddenInput.setAttribute( 'type', 'hidden' );
	hiddenInput.setAttribute( 'name', 'action' );
	hiddenInput.value = element.getAttribute( 'value' );

	form.append( hiddenInput );
	form.submit();
};

/**
 * @function setupCopyButton
 * @description Configure the copy button.
 */
const setupCopyButton = () => {
	if ( ! ClipboardJS.isSupported() ) {
		return;
	}

	return new ClipboardJS( '[data-js="copy-button"]', {
		target: ( trigger ) => {
			const container = trigger.closest( '.form-control-field-help-container' );
			return container.querySelector( '.form-control-field--copy' );
		},
	} );
};

/**
 * @function selectFieldValue
 * @description Triggers when clicking the field that contains the value to copy.
 */
const selectFieldValue = ( event ) => {
	const field = event.target;
	const container = field.closest( '.form-control-field-help-container' );
	const copyButton = container.querySelector( '[data-js="copy-button"]' );

	if ( ! copyButton ) {
		return;
	}

	copyButton.click();
};

const setupDnsStatus = () => {
	document.querySelectorAll( '[data-js="dns-status"]' ).forEach( ( element ) => {
		const status = element.value;
		const dnsContainer = element.closest( '.form-control-custom-style--field-group-dns' );

		if ( ! dnsContainer ) {
			return;
		}

		const dnsTitle = dnsContainer.querySelector( '.form-control-title--group-dns' );

		const statusElement = document.createElement( 'span' );
		statusElement.classList.add( 'form-control-title--group-dns-status', 'pill' );
		statusElement.innerText = status === 'unknown' ? 'pending' : status;

		if ( status === 'valid' ) {
			statusElement.classList.add( 'form-control-title--group-dns-status--verified', 'pill--success' );
		} else {
			statusElement.classList.add( 'form-control-title--group-dns-status--unverified', 'pill--warning' );

			setTimeout( () => {
				// Extra check to prevent hiding the button if one of the DNS needs to be verified.
				const verifyDNS = document.querySelector( '[value="verify_dns"]' );
				verifyDNS.style = '';
				verifyDNS.nextElementSibling.style = '';
			}, 400 );
		}

		dnsTitle.append( statusElement );
	} );
};

const setupDomainState = () => {
	document.querySelectorAll( '[data-js="domain-state"]' ).forEach( ( element ) => {
		const status = element.value;
		const container = element.closest( '.form-control-numbered-section' );

		if ( ! container ) {
			return;
		}

		const sectionTitle = container.querySelector( '.form-control-numbered-section-item-title' );

		const statusElement = document.createElement( 'span' );
		statusElement.classList.add( 'form-control-title-domain-state', 'pill' );
		statusElement.innerText = status;

		if ( status === 'active' ) {
			statusElement.classList.add( 'form-control-title-domain-state--verified', 'pill--success' );
			const verifyDNS = document.querySelector( '[value="verify_dns"]' );
			verifyDNS.style = 'display: none;';
			verifyDNS.nextElementSibling.style = 'display: none;';
		} else {
			statusElement.classList.add( 'form-control-title-domain-state--unverified', 'pill--error' );
		}

		sectionTitle.append( statusElement );
	} );
};

/**
 * @function bindEvents
 * @description Bind the events for this module.
 */
const bindEvents = () => {
	setupCopyButton();
	setupFromEmailDomainMask();
	setupDomainState();
	setupDnsStatus();
	delegate( document, '[data-js="copy-field"]', 'click', selectFieldValue );
	delegate( document, '[data-js="submit-delete"]', 'click', submitDeleteButton );

	if ( ! ClipboardJS.isSupported() ) {
		document.querySelectorAll( '.form-control-field-help-action-button' ).forEach( ( field ) => {
			field.style = 'display: none;';
		} );
	}
};

/**
 * @function init
 * @description Kick off this modules functions
 */

const mailDelivery = () => {
	bindEvents();
};

export default mailDelivery;
