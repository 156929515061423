/**
 * @module
 * @exports viewportDims
 * @description Sets viewport dimensions using verge on shared state
 * and detects mobile or desktop state.
 */

import verge from 'verge';
import state from '../config/state';
import { MOBILE_BREAKPOINT } from '../config/options';

const viewportDims = () => {
	state.vHeight = verge.viewportH();
	state.vWidth = verge.viewportW();

	if ( state.vWidth >= MOBILE_BREAKPOINT ) {
		state.isDesktop = true;
		state.isMobile = false;
	} else {
		state.isDesktop = false;
		state.isMobile = true;
	}
};

export default viewportDims;
