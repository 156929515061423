/**
 * @module
 * @description JS to power notifications
 */
import { route } from 'common/api';
import { trigger } from 'utils/events';
import * as tools from 'utils/tools';

const el = {
	notification: tools.getNodes( 'notification' ),
};

/**
 * @function handleDismissFormSubmit
 * @description Handles form submit via ajax and closes notification
 * @param {Event} e
 * @param notification
 * @param notificationDismissForm
 * @async
 */
const handleDismissFormSubmit = async( e, notification, notificationDismissForm ) => {
	e.preventDefault();
	const endpoint = notificationDismissForm.getAttribute( 'action' );

	if ( endpoint ) {
		notificationDismissForm.removeEventListener( 'submit', handleDismissFormSubmit );
		notification.parentNode.removeChild( notification );
		trigger( { event: 'modern_tribe/notification_dismissed', native: false } );
		await route( endpoint, {
			method: 'DELETE',
		} );
	}
};

/**
 * @function bindEvents
 * @description Bind event handlers to listeners
 */
const bindEvents = ( notification ) => {
	const notificationDismissForm = tools.getNodes( 'notification__dismiss-form', false, notification )[ 0 ];
	if ( notificationDismissForm ) {
		notificationDismissForm.addEventListener( 'submit', ( e ) => handleDismissFormSubmit( e, notification, notificationDismissForm ) );
	}
};

/**
 * @function init
 * @description Kick off this modules functions
 */
const init = () => {
	if ( el.notification ) {
		el.notification.forEach( notification => bindEvents( notification ) );
	}
};

export default init;
