/**
 * @module
 * @description Delete
 */
import * as tools from 'utils/tools';
import { deleteMessage } from './service';

const el = {
	deleteButton: tools.getNodes( 'timeline__delete-message-button' )[ 0 ],
	deleteMenuButtons: tools.getNodes( 'timeline__message-menu-button--delete', true ),
	deleteSelect: tools.getNodes( 'timeline__delete-message-select' )[ 0 ],
};

const state = {
	id: 0,
};

/**
 * @function onMenuButtonClick
 * @description Handles delete menu button click event
 * @param {Event} e
 */
const onMenuButtonClick = ( e ) => {
	const shortcut = tools.closest( e.target, '.timeline__message' );
	const triggered = tools.closest( e.target, '.timeline__accordion-message' );
	if ( triggered ) {
		state.id = Number( triggered.dataset.id );
		state.sourceId = Number( triggered.dataset.sourceId );
	} else {
		state.id = Number( shortcut.dataset.id );
	}
};

/**
 * @function onConfirmClick
 * @description Handles confirm button click event
 * @async
 */
const onConfirmClick = async() => {
	const response = await deleteMessage( state.id, state.sourceId );
	if ( response.status === 200 && response.data.redirect ) {
		window.location = response.data.redirect;
	}
};

/**
 * @function onSelectChange
 * @description Handles select change event
 * @param {Event} e
 */
const onSelectChange = ( e ) => {
	e.target.value === 'yes'
		? el.deleteButton.removeAttribute( 'disabled' )
		: el.deleteButton.setAttribute( 'disabled', true );
};

/**
 * @function bindEvents
 * @description Bind event handlers to listeners
 */
const bindEvents = () => {
	el.deleteMenuButtons.forEach( button => (
		button.addEventListener( 'click', onMenuButtonClick )
	) );
	el.deleteButton.addEventListener( 'click', onConfirmClick );
	el.deleteSelect.addEventListener( 'change', onSelectChange );
};

/**
 * @function init
 * @description Kick off this modules functions
 */
const init = () => {
	if ( el.deleteButton ) {
		bindEvents();
	}
};

export default init;
