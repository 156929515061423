import axios from 'axios';
import { route } from 'common/api';

export const check = ( user_id ) => route( `/onboarding/status/${ user_id }/` );

export const markOnboarded = () => axios.post( '/onboarding/finish' );
export const auth = ( { website, user } ) => axios.get( `/onboarding/auth?website=${ website }&user=${ user }` );

export const addUser = ( {
	email,
	password,
	source_id,
	first_name,
	last_name,
	license_key,
	company,
	timezone,
} ) => route( '/onboarding/user/', {
	method: 'POST',
	body: JSON.stringify( {
		email,
		password,
		source_id,
		first_name,
		last_name,
		license_key,
		company,
		timezone,
	} ),
} );
