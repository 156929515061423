import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { __ } from 'utils/translations';

const Undo = ( { undo, shortcutTitle } ) => (
	<Fragment>
		{__( 'campaigns.notifications.shortcut-removed', { shortcutTitle } )}
		<button onClick={ undo }>
			{ __( 'globals.undo' ) }
		</button>
	</Fragment>
);

Undo.propTypes = {
	undo: PropTypes.func.isRequired,
	shortcutTitle: PropTypes.string.isRequired,
};

export default Undo;
