// Keep in sync with Message model statuses

export const DRAFT = 'draft';
export const SCHEDULED = 'scheduled';
export const QUEUED = 'queued';
export const PROCESSING = 'processing';
export const SENT = 'sent';
export const FAILURE = 'failure';
export const TRASH = 'trash';
export const ACTIVE = 'active';
export const INACTIVE = 'inactive';
