/**
 * @module
 * @description Preview Service
 */

import { route } from 'common/api';

export async function previewShortcut( shortcutId, shortcutType ) {
	const { view = '' } = await route( `shortcuts/preview/`, {
		method: 'POST',
		body: JSON.stringify( {
			id: shortcutId,
			type: shortcutType,
			preview: true,
		} ),
	} );
	return view;
}
