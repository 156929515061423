/**
 * @module
 * @description Delete
 */
import * as tools from 'utils/tools';
import { on, trigger } from 'utils/events';

const el = {
	editLinks: tools.getNodes( 'shortcut__menu-button--edit-shortcut', true ),
	description: tools.getNodes( 'modal__edit-card-description' )[ 0 ],
	image: tools.getNodes( 'modal__edit-card-img' )[ 0 ],
	title: tools.getNodes( 'modal__edit-card-title' )[ 0 ],
	editButton: tools.getNodes( 'modal__edit-button' )[ 0 ],
	select: tools.getNodes( 'modal__edit-select' )[ 0 ],
};

let shortcut = null;

function onEditButtonClick( e ) {
	shortcut = tools.closest( e.target, '.shortcut' );
	el.image.style = tools.getNodes( '.shortcut__img', false, shortcut, true )[ 0 ].style.cssText;
	el.title.textContent = tools.getNodes( '.shortcut__title', false, shortcut, true )[ 0 ].textContent;
	const description = tools.getNodes( '.shortcut__description', false, shortcut, true );
	if ( description.length ) {
		el.description.textContent = description[ 0 ].textContent;
	}
}

function handleRouteAction( container ) {
	const route = container.getAttribute( 'data-route' );
	if ( route ) {
		window.location.href = route;
	} else {
		trigger( { event: 'modern_tribe/edit_virtual_shortcut', native: false, data: { shortcut: container } } );
	}
}

function onConfirmClick() {
	handleRouteAction( shortcut );
}

function onSelectChange( e ) {
	e.target.value === 'yes'
		? el.editButton.removeAttribute( 'disabled' )
		: el.editButton.setAttribute( 'disabled', true );
}

function onLinkClick( e ) {
	e.preventDefault();
	onEditButtonClick( e );
	$( '#modal__edit-shortcut' ).foundation( 'open' );
}

function addBehavior( card ) {
	const link = card.querySelector( 'a.shortcut__menu-link' );
	const virtualBtn = card.querySelector( 'button.shortcut__menu-button--edit-virtual-shortcut' );
	const btn = card.querySelector( 'button.shortcut__menu-button--edit-shortcut' );

	if ( link ) {
		link.addEventListener( 'click', onLinkClick );
	} else if ( virtualBtn ) {
		trigger( { event: 'modern_tribe/remove_edit_virtual_shortcut_listener', native: false, data: { btn: virtualBtn } } );
		virtualBtn.addEventListener( 'click', onLinkClick );
	} else {
		btn.removeEventListener( 'click', editLink );
		btn.addEventListener( 'click', onLinkClick );
	}
}

function editLink( e ) {
	const container = tools.closest( e.target, '.shortcut' );
	handleRouteAction( container );
}

function removeBehavior( card ) {
	const link = card.querySelector( '.shortcut__menu-link' );
	const virtualBtn = card.querySelector( 'button.shortcut__menu-button--edit-virtual-shortcut' );
	const btn = card.querySelector( 'button.shortcut__menu-button--edit-shortcut' );

	if ( link ) {
		link.removeEventListener( 'click', onLinkClick );
	} else if ( virtualBtn ) {
		virtualBtn.removeEventListener( 'click', onLinkClick );
		trigger( { event: 'modern_tribe/add_edit_virtual_shortcut_listener', native: false, data: { btn: virtualBtn } } );
	} else {
		btn.removeAttribute( 'data-open' );
		btn.removeEventListener( 'click', onLinkClick );
		btn.removeEventListener( 'click', onEditButtonClick );
		btn.addEventListener( 'click', editLink );
	}
}

function campaign_updates( { detail = {} } ) {
	const { action = '', id = 0, shortcutType = '' } = detail;
	const card = document.querySelector( `.card[data-id="${ id }"][data-type="${ shortcutType }"]` );

	if ( ! card ) {
		return;
	}
	switch ( action ) {
		case 'add':
			addBehavior( card );
			break;
		case 'remove':
			removeBehavior( card );
			break;
		default:
			break;
	}
}

/**
 * @function bindEvents
 * @description Bind event handlers to listeners
 */
const bindEvents = () => {
	if ( el.editLinks ) {
		el.editLinks.forEach( link => (
			link.addEventListener( 'click', onEditButtonClick )
		) );
	}
	if ( el.editButton ) {
		el.editButton.addEventListener( 'click', onConfirmClick );
	}
	if ( el.select ) {
		el.select.addEventListener( 'change', onSelectChange );
	}
	on( document, 'modern_tribe/campaign_shortcut_added_removed', campaign_updates );
};

/**
 * @function init
 * @description Kick off this modules functions
 */
const init = () => {
	bindEvents();
};

export default init;
