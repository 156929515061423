/**
 * @module
 * @description Duplicate
 */
import * as tools from 'utils/tools';
import { duplicateShortcut } from './service';
import { on } from 'utils/events';

const DUPLICATE = 'duplicate';
const EDIT = 'edit';

const el = {
	duplicateButtons: tools.getNodes( 'shortcut__menu-button--duplicate-shortcut', true ),
	editButtons: tools.getNodes( 'shortcut__menu-button--edit-virtual-shortcut', true ),
};

const performAction = ( action ) => async( shortcut ) => {
	const id = Number( shortcut.dataset.id );
	const type = shortcut.dataset.type;

	const response = await duplicateShortcut( id, type, action );
	if ( response.status === 200 && response.data.redirect ) {
		window.location = response.data.redirect;
	}
};

/**
 * @function onClick
 * @description Handle click event
 * @async
 * @param action
 */
const onClick = ( action ) => async function handler( e ) {
	const shortcut = tools.closest( e.target, '.shortcut' );
	await performAction( action )( shortcut );
};

const editListener = async( e ) => {
	const { detail = {} } = e;
	const { shortcut } = detail;
	if ( ! shortcut ) {
		return;
	}

	await performAction( EDIT )( shortcut );
};

/**
 * @function bindEvents
 * @description Bind event handlers to listeners
 */
const bindEvents = () => {
	el.duplicateButtons.forEach( button => (
		button.addEventListener( 'click', onClick( DUPLICATE ) )
	) );
	el.editButtons.forEach( button => {
		button._listener = onClick( EDIT );
		button.addEventListener( 'click', button._listener );
	} );

	on( document, 'modern_tribe/edit_virtual_shortcut', editListener );
	on( document, 'modern_tribe/add_edit_virtual_shortcut_listener', ( { detail = {} } ) => {
		const { btn } = detail;
		if ( btn && btn._listener ) {
			btn.addEventListener( 'click', btn._listener );
		}
	} );
	on( document, 'modern_tribe/remove_edit_virtual_shortcut_listener', ( { detail = {} } ) => {
		const { btn } = detail;
		if ( btn && btn._listener ) {
			btn.removeEventListener( 'click', btn._listener );
		}
	} );
};

/**
 * @function init
 * @description Kick off this modules functions
 */
const init = () => {
	bindEvents();
};

export default init;
