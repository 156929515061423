import bugsnag from '@bugsnag/js';

let bugsnagClient;

try {
	window.bugsnagClient = bugsnagClient = bugsnag( {
		apiKey: process.env.BUGSNAG_API_KEY_JS,
		appVersion: process.env.APP_VERSION || '0.0.0',
	} );
} catch ( error ) {
	console.error( error );
}

export default bugsnagClient;
