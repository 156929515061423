/**
 * @module
 * @description Base content module for shared content js and props.
 */

import profile from './profile';
import website from './website';
import review from './review';

/**
 * @function init
 * @description Kick off this modules functions
 */

const init = () => {
	profile();
	website();
	review();
};

export default init;
