/**
 * @module
 * @description Sorting Audience Listing
 */

import * as tools from '../utils/tools';
import List from 'list.js';

const el = {
	form: tools.getNodes( 'audience-filter-form' )[ 0 ],
	userList: document.getElementById( 'user-list' ),
	empty: tools.getNodes( 'empty-list' )[ 0 ],
};

const instances = {};

/**
 * @function initList
 * @description Init the List JS on load.
 */
const initList = () => {
	const options = {
		valueNames: [ 'email', 'first', 'last', 'date' ],
	};

	instances.searchList = new List( el.userList, options );

	instances.searchList.on( 'updated', list => {
		if ( list.matchingItems.length > 0 ) {
			el.empty.classList.remove( 'is-active' );
		} else {
			el.empty.classList.add( 'is-active' );
		}
	} );
};

/**
 * @function preventSubmit
 * @description No need to submit the form as this works on keypu.
 */
const preventSubmit = ( e ) => {
	e.preventDefault();
	e.stopImmediatePropagation();
};

/**
 * @function bindEvents
 * @description Bind the events for this module.
 */
const bindEvents = () => {
	el.form.addEventListener( 'submit', preventSubmit );
};

/**
 * @function init
 * @description Kick off this modules functions
 */

const init = () => {
	if ( ! el.form ) {
		return;
	}

	initList();
	bindEvents();
};

export default init;
