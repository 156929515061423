/**
 * @module
 * @description Utils for translations
 */

import translations from 'config/translations';

const __ = ( id, values = {} ) => {
	let translation = id.split( /\.(.+)/ ).slice( 0, -1 ).reduce(
		( o, x ) => typeof o === 'undefined' || o === null ? o : o[ x ],
		translations
	);

	if ( translation ) {
		for ( const value in values ) {
			translation = translation.replace( /{(\w)+}/, values[ value ] );
		}
	}

	return translation || id;
};

export { __ };
