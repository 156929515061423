import { success, error, info } from 'utils/notifications';
import * as tools from 'utils/tools';

const el = {
	toastNotificationSuccessBtn: tools.getNodes( 'design-system__toast-notifications__show-success' )[ 0 ],
	toastNotificationErrorBtn: tools.getNodes( 'design-system__toast-notifications__show-error' )[ 0 ],
	toastNotificationInfoBtn: tools.getNodes( 'design-system__toast-notifications__show-info' )[ 0 ],
};

const bindEvents = () => {
	el.toastNotificationSuccessBtn.addEventListener( 'click', () => success( 'Success :)' ) );
	el.toastNotificationErrorBtn.addEventListener( 'click', () => error( 'Error :(' ) );
	el.toastNotificationInfoBtn.addEventListener( 'click', () => info( 'Info :|' ) );
};

const init = () => {
	bindEvents();
};

export default init;
