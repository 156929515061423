/**
 * @module
 * @description Handle sliding of horizontal content
 */

import * as tools from '../utils/tools';
import * as cookies from '../utils/storage/cookies';
import verge from 'verge';
import Swiper from 'swiper';

const el = {
	container: tools.getNodes( 'slide-content' )[ 0 ],
};

const sliderState = {
	initialized: 'false',
	slider: false,
};

/**
 * @function initSchema
 * @description kick off content slider.
 */

const initSlide = () => {
	sliderState.slider = new Swiper( '[data-js="slide-content"]', {
		slidesPerView: 'auto',
		freeMode: true,
		freeModeMomentumBounce: false,
		resistanceRatio: 0,
		threshold: 10,
		keyboard: {
			endabled: true,
		},
	} );

	sliderState.initialized = 'true';
};

/**
 * @function checkFirstLoad
 * @description check to see if slider animation has ever run on users screen. If it hasn't, run animation
 */

const checkFirstLoad = () => {
	// check cookie for first load
	const firstLoad = cookies.getCookie( 'tribe-first-load' );

	// if there, exit out
	if ( firstLoad || verge.viewportW() >= 1330 ) {
		return;
	}

	// if not, add class to swiper to have animation run
	document.querySelector( '.swiper-wrapper' ).classList.add( 'first-load' );

	// then add in cookie
	cookies.setCookie( 'tribe-first-load', true, 1 );
};

/**
 * @function init
 * @description Kick off this modules functions
 */

const slide = () => {
	if ( ! el.container ) {
		return;
	}

	initSlide();

	checkFirstLoad();
};

export default slide;
