/**
 * @module
 * @exports ready
 * @description The core dispatcher for the dom ready event javascript.
 */

import _ from 'lodash';
import * as events from 'utils/events';
import * as tools from 'utils/tools';
import applyBrowserClasses from '../utils/dom/apply-browser-classes';

// you MUST do this in every module you use lodash in.
// A custom bundle of only the lodash you use will be built by babel.

import resize from './resize';
import plugins from './plugins';
import viewportDims from './viewport-dims';
import slide from './slide';

import forms from '../forms/index';
import audience from '../audience/index';
import nav from '../nav/index';
import shortcuts from '../shortcuts';
import eventsJS from '../events';
import subscribers from '../subscribers';
import timelines from '../timelines';
import notifications from '../notifications';
import designSystem from '../design-system';
import ui from '../ui';
import './bugsnag';

const el = {
	emailEditorAppRoot: tools.getNodes( 'email-editor-app' )[ 0 ],
	emailSettingsAppRoot: tools.getNodes( 'email-settings-app' )[ 0 ],
	subscriberAppRoot: tools.getNodes( 'subscriber-app' )[ 0 ],
	timelineAppRoot: tools.getNodes( 'timeline-app' )[ 0 ],
	audiencesListAppRoot: tools.getNodes( 'audience-list-app' )[ 0 ],
	audiencesListCreatorAppRoot: tools.getNodes( 'audience-list-creator-app' )[ 0 ],
	shortCutsAppRoot: tools.getNodes( 'shortcuts-app' )[ 0 ],
	campaignAppRoot: tools.getNodes( 'campaign-app' )[ 0 ],
	listAppRoot: tools.getNodes( 'list-app' )[ 0 ],
	notificationsAppRoot: tools.getNodes( 'notifications-app' )[ 0 ],
	hmrScript: tools.getNodes( '#hmr-enabled', false, document, true )[ 0 ],
};

/**
 * @function bindEvents
 * @description Bind global event listeners here,
 */

const bindEvents = () => {
	events.on( window, 'resize', _.debounce( resize, 200, false ) );
};

/**
 * @function browserSupportsAllFeatures
 * @description Add feature detects here like window.IntersectionObserver || window.Fetch etc
 */

const browserSupportsAllFeatures = () => window.IntersectionObserver;

/**
 * @function init
 * @description The core dispatcher for init across the codebase.
 */

const init = () => {
	// apply browser classes

	applyBrowserClasses();

	// init external plugins

	plugins();

	// set initial states

	viewportDims();

	// initialize global events

	bindEvents();

	// initialize the main scripts

	slide();
	nav();
	forms();
	audience();
	shortcuts();
	eventsJS();
	subscribers();
	timelines();
	notifications();
	ui();

	if ( window.location.href.indexOf( 'design-system' ) > -1 ) {
		designSystem();
	}

	if ( window.tinymce ) {
		window.tinymce.suffix = '.min';
		window.tinymce.baseURL = '/vendor/tinymce';
	}

	if ( el.emailEditorAppRoot && ! el.hmrScript ) {
		import( 'EmailEditor' /* webpackChunkName:"email-editor-app" */ );
	}

	if ( el.emailSettingsAppRoot && ! el.hmrScript ) {
		import( 'EmailSettings' /* webpackChunkName:"email-settings-app" */ );
	}

	if ( el.audiencesListAppRoot && ! el.hmrScript ) {
		import( 'AudienceList' /* webpackChunkName:"audience-list-app" */ );
	}

	if ( el.audiencesListCreatorAppRoot && ! el.hmrScript ) {
		import( 'AudienceListCreator' /* webpackChunkName:"audience-list-creator-app" */ );
	}

	if ( el.shortCutsAppRoot && ! el.hmrScript ) {
		import( 'Shortcuts' /* webpackChunkName:"shortcuts-app" */ );
	}

	if ( el.campaignAppRoot && ! el.hmrScript ) {
		import( 'Campaign' /* webpackChunkName:"campaign-app" */ );
	}

	if ( el.subscriberAppRoot && ! el.hmrScript ) {
		import( 'Subscribers' /* webpackChunkName:"subscriber-app" */ );
	}

	if ( el.timelineAppRoot && ! el.hmrScript ) {
		import( 'Timeline' /* webpackChunkName:"timeline-app" */ );
	}

	if ( el.listAppRoot && ! el.hmrScript ) {
		import( 'List' /* webpackChunkName:"list-app" */ );
	}

	if ( el.notificationsAppRoot && ! el.hmrScript ) {
		import( 'Notifications' /* webpackChunkName:"notifications-app" */ );
	}

	console.info(
		'Promoter FE: Initialized all javascript that targeted document ready.'
	);
};

/**
 * @function setupEnivironment
 * @description
 */

const setupEnvironment = () => {
	if ( browserSupportsAllFeatures() ) {
		init();
		return;
	}
	import( './polyfills' /* webpackChunkName:"polyfills" */ ).then( () => init() );
};

/**
 * @function domReady
 * @description Export our dom ready enabled init.
 */

const domReady = () => {
	events.ready( setupEnvironment );
};

export default domReady;
