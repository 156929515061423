/**
 * @module
 * @description Onboarding Flow
 */

import delegate from 'delegate';
import * as tools from 'utils/tools';
import ui from './ui';
import services from './services';

import { route } from 'common/api';

const el = {
	container: tools.getNodes( 'onboarding-review' )[ 0 ],
	userId: tools.getNodes( 'user-id' )[ 0 ],
};

/**
 * @function completeOnboarding
 * @description complete the onboarding process and go to the events page
 */

const completeOnboarding = () => {
	ui.loader.show();

	route( '/events/sync', { method: 'POST' } )
		.then( () => {
			services.auth.markOnboarded()
				.then( () => window.location.pathname = '/events' );
		} )
		.catch( ( error ) => {
			console.error( error.response );
		} )
		.then( () => {
			ui.loader.hide();
		} );
};

/**
 * @function bindEvents
 * @description Bind the events for this module.
 */

const bindEvents = () => {
	delegate( document, '[data-js="complete-onboarding"]', 'click', completeOnboarding );
};

/**
 * @function init
 * @description Kick off this modules functions
 */

const review = () => {
	if ( ! el.container ) {
		return;
	}
	bindEvents();
};

export default review;
