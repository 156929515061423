/**
 * @module
 * @description Search
 */

import * as tools from 'utils/tools';

const el = {
	eventTitles: tools.getNodes( 'event-title' ),
};

export const onHashChange = ( card = null ) => {
	let cardEl = card;
	if ( cardEl === null ) {
		cardEl = document.querySelector(
			`#card__${ window.location.hash.slice( 1 ) }`
		);
	}

	if ( cardEl ) {
		const title = cardEl.querySelector( '.shortcut__title' ).textContent;
		Array.from( el.eventTitles ).forEach( node => ( node.textContent = title ) );
	}
};

const initialSync = () => {
	if ( window.location.hash ) {
		onHashChange();
	}
};

const bind = () => {
	el.eventTitles && window.addEventListener( 'hashchange', onHashChange );
};

/**
 * @function init
 * @description Kick off this modules functions
 */

const init = () => {
	bind();
	initialSync();
};

export default init;
