/**
 * @module
 * @description Onboarding Flow
 */

import delegate from 'delegate';
import * as tools from 'utils/tools';

import ui from './ui';
import services from './services';

const el = {
	container: tools.getNodes( 'onboarding-authorization' )[ 0 ],
	license: tools.getNodes( 'license-input' )[ 0 ],
	userId: tools.getNodes( 'user-id' )[ 0 ],
	loading: tools.getNodes( 'section-loading' )[ 0 ],
	nextSync: tools.getNodes( 'next-sync' )[ 0 ],
	review: tools.getNodes( 'section-review' )[ 0 ],
	websiteListing: tools.getNodes( 'website-listing' )[ 0 ],
	websites: tools.getNodes( 'section-websites' )[ 0 ],
};

const userData = {
	user: '',
	installs: '',
	selectedSite: '',
};

/**
 * @function renderWebsites
 * @description Renders websites belonging to user as radio inputs
 */
const renderWebsites = () => {
	let html = '';

	for ( const value of userData.installs ) {
		html += `
			<div class="form-control-radio form-control-custom-style">
				<input class="form-control-radio__input" type="radio" name="website-to-sync" value="${ value }" id="${ value }">
				<label class="form-control-radio__label" for="${ value }">${ value }</label>
			</div>
		`;
	}

	el.websiteListing.insertAdjacentHTML( 'beforeend', html );
};

/**
 * @function fetchWebsites
 * @description Fetch websites belonging to license key
 */
const fetchWebsites = () => {
	ui.loader.show();

	services.provider.getDomains( el.license.value )
		.then( ( { user, installs } ) => {
			// save data for future steps
			userData.user = user;
			userData.installs = installs;

			renderWebsites();
		} )
		.catch( ( error ) => {
			console.error( error );
		} )
		.then( () => {
			ui.loader.hide();
		} );
};

/**
 * @function websiteSelected
 * @description radio button was selected to sync and move forward in onboarding
 */
const websiteSelected = ( e ) => {
	el.nextSync.disabled = false;
	el.nextSync.classList.remove( 'btn--disabled' );

	userData.selectedSite = e.target.value;
};

/**
 * @function goToAuth
 * @description trigger the auth flow bringing us to user's wordpress site
 */

const goToAuth = async() => {
	const user_id = el.userId.value;
	const provider_url = userData.selectedSite;
	const provider_type = '';

	ui.loader.show();

	try {
		await services.provider.update( { user_id, provider_url, provider_type } );
		const { data } = await services.auth.auth( { user: user_id, website: provider_url } );
		console.warn( data.redirect );
		window.location.href = data.redirect;
	} catch ( error ) {
		console.error( error );
	} finally {
		ui.loader.hide();
	}
};

/**
 * @function bindEvents
 * @description Bind the events for this module.
 */

const bindEvents = () => {
	delegate( document, '[name="website-to-sync"]', 'click', websiteSelected );
	delegate( document, '[data-js="next-sync"]', 'click', goToAuth );
};

/**
 * @function initialize
 * @description Initial setup for authorization
 */
const initialize = () => {
	fetchWebsites();
};

/**
 * @function init
 * @description Kick off this modules functions
 */

const onboarding = () => {
	if ( ! el.container ) {
		return;
	}

	bindEvents();
	initialize();
};

export default onboarding;
