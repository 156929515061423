/**
 * @module
 * @description Duplicate Service
 */
import axios from 'axios';
import { DUPLICATE_MESSAGE_ENDPOINT } from 'config/settings';

export const duplicateMessage = async( id ) => {
	const response = await axios.post( `${ DUPLICATE_MESSAGE_ENDPOINT }/${ id }` );
	return response;
};
