/**
 * @module
 * @description Onboarding Flow
 */

import * as tools from 'utils/tools';

const el = {
	loading: tools.getNodes( 'section-loading' )[ 0 ],
};

export const show = () => el.loading.classList.add( 'is-active' );
export const hide = () => el.loading.classList.remove( 'is-active' );
