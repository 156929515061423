const settings = window.modern_tribe_config || {};

export const API_URL = 'https://jsonplaceholder.typicode.com';
export const TEMPLATE_URL = settings.template_url;
export const MESSAGE = settings.message || {};
export const SHORTCUT = settings.shortcut || {};
export const BASE_AUDIENCES = settings.base_audiences || {};
export const EVENT_AUDIENCES = settings.event_audiences || {};
export const MESSAGE_AUDIENCES = settings.message_audiences || [];
export const SHORTCUT_AUDIENCES = settings.shortcut_audiences || [];
export const CUSTOM_LISTS = settings.custom_lists || [];
export const MERGE_TAGS = settings.merge_tags || {};
export const SHORTCUT_ICONS = settings.shortcut_icons || [];
export const SHORTCUTS = settings.shortcuts || [];
export const SCHEDULE = settings.message ? settings.message.schedule || {} : {};
export const GLOBAL = settings.global || {};
export const EMAIL_EDITOR_APP_TYPE = settings.email_editor_app || '';
export const IMAGES_URL = GLOBAL.image_path || '';
export const AUTH_CONNECTOR_URL = GLOBAL.onboarding_auth_url || '';
export const USER_PROFILE_URL = GLOBAL.user_profile_url || '';
export const AJAX_ACTIONS = GLOBAL.ajax_actions || {};
export const MESSAGE_SETTINGS = settings.message_settings || {};
export const BASE_URL = GLOBAL.base_url || '';
export const LIST_EDITOR_SETTINGS = settings.list_editor_settings || {};
export const CUSTOM_LIST = settings.custom_list || {};
export const BASE_EVENT_ID = settings.base_event_id || null;
export const BASE_EVENT_SOURCE_ID = settings.base_event_source_id || null;
export const BASE_EVENT_START_DATETIME = settings.base_event_start_datetime || null;
export const EVENT_DATA = settings.event_data || {};
export const TRIGGERS = settings.triggers || {};
export const CAMPAIGNS = settings.campaigns || [];
export const CAMPAIGNS_USED = settings.campaigns_used || {};
export const STARTER = settings.starter || [];
export const TASKS = settings.tasks || {};

export const USER = GLOBAL.user || {};

export const ROUTES = GLOBAL.routes || {};

export const ROUTES_USER = ROUTES.user || {};
export const ROUTES_USER_VERIFY_NOW = ROUTES_USER.verify_now || '';

export const ROUTES_TASK = ROUTES.task || {};
export const TASK_COMPLETE = ROUTES_TASK.complete || '';

export const MESSAGES_STANDARD_ENDPOINT = `${ BASE_URL }/messages/standard`;
export const MESSAGES_TRIGGER_ENDPOINT = `${ BASE_URL }/messages/trigger`;
export const SHORTCUTS_ENDPOINT = `${ BASE_URL }/shortcuts`;
export const DUPLICATE_SHORTCUTS_ENDPOINT = `${ BASE_URL }/duplicate-shortcuts`;
export const LISTS_ENDPOINT = `${ BASE_URL }/lists`;
export const SUBSCRIBERS_ENDPOINT = `${ BASE_URL }/subscribers`;
export const EVENTS_ENDPOINT = `${ BASE_URL }/events`;

export const DUPLICATE_MESSAGE_ENDPOINT = `${ BASE_URL }/messages/standard/duplicate`;

export const CAMPAIGN_ENDPOINT = `${ BASE_URL }/campaign`;
