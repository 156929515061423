/**
 * @module
 * @description JS to power timelines
 */
import quickTips from './quick-tips';
import duplicateMessage from './duplicate';
import activateMessage from './activate';
import deleteMessage from './delete';

/**
 * @function init
 * @description Initializes timelines JS
 */
const init = () => {
	quickTips();
	duplicateMessage();
	activateMessage();
	deleteMessage();
};

export default init;
