/**
 * @module
 * @description JS to augment Foundation dropdown panes' functionality
 */
import * as tools from 'utils/tools';

const el = {
	dropdownPane: tools.getNodes( 'dropdown-pane' ),
};

/**
 * @function handleDropdownPaneItemsHover
 * @description Handles form submit via ajax and closes notification
 * @param {Event} e
 * @async
 */
const handleDropdownPaneItemsMouseEnter = ( dropdownPane, target ) => {
	dropdownPane.classList.add( 'hover' );
	target.classList.add( 'active' );
};

const handleDropdownPaneItemsMouseLeave = ( dropdownPane, target ) => {
	target.classList.remove( 'active' );
	dropdownPane.classList.remove( 'hover' );
};

/**
 * @function bindEvents
 * @description Bind event handlers to listeners
 */
const bindEvents = ( dropdownPane ) => {
	const dropdownPaneItems = tools.getNodes( 'ul > li', false, dropdownPane, true );
	if ( dropdownPaneItems ) {
		dropdownPaneItems.forEach( item => {
			item.addEventListener( 'mouseenter', () => handleDropdownPaneItemsMouseEnter( dropdownPane, item ) );
			item.addEventListener( 'mouseleave', () => handleDropdownPaneItemsMouseLeave( dropdownPane, item ) );
		} );
	}
};

/**
 * @function init
 * @description Kick off this modules functions
 */
const init = () => {
	if ( el.dropdownPane ) {
		el.dropdownPane.forEach( dropdownPane => bindEvents( dropdownPane ) );
	}
};

export default init;
