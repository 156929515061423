/**
 * @module
 * @description Mobile Navigation
 */

import * as tools from 'utils/tools';

const el = {
	eventHeaderToggle: tools.getNodes( 'event-header-toggle' )[ 0 ],
	buttonToggle: tools.getNodes( 'event-list-toggle' )[ 0 ],
};

const toggleEventHeader = () => {
	el.eventHeaderToggle.classList.toggle( 'isOpen' );
};

const bindEvents = () => {
	el.buttonToggle.addEventListener( 'click', toggleEventHeader );
};

/**
 * @function init
 * @description Kick off this modules functions
 */

const init = () => {
	if ( ! el.eventHeaderToggle ) {
		return;
	}

	bindEvents();
};

export default init;
