/**
 * @module
 * @description Mobile Navigation
 */

import * as tools from '../utils/tools';
import * as bodyLocker from '../utils/dom/body-lock';
import state from '../config/state';

const el = {
	button: tools.getNodes( '[data-toggle="barnav"]', true, document, true )[ 0 ],
	siteContain: tools.getNodes( 'html', true, document, true )[ 0 ],
};

/**
 * @function toggleVisual
 * @description Change the look of the menu button on click
 */

const toggleVisual = () => {
	el.button.classList.toggle( 'is-active' );
	el.siteContain.classList.toggle( 'is-active' );

	if ( el.siteContain.classList.contains( 'is-active' ) ) {
		bodyLocker.lock();
	} else {
		bodyLocker.unlock();
	}
};

const handleResize = () => {
	if ( state.vWidth >= 640 && tools.hasClass( el.button, 'is-active' ) ) {
		toggleVisual();
	}
};

/**
 * @function bindEvents
 * @description Bind the events for this module.
 */

const bindEvents = () => {
	el.button.addEventListener( 'click', toggleVisual );
	document.addEventListener( 'modern_tribe/resize_executed', handleResize );
};

/**
 * @function init
 * @description Kick off this modules functions
 */

const validation = () => {
	if ( ! el.button ) {
		return;
	}

	bindEvents();
};

export default validation;
