/**
 * @module
 * @description Duplicate
 */
import * as tools from 'utils/tools';
import { duplicateMessage } from './service';

const el = {
	duplicateButtons: tools.getNodes( 'timeline__message-menu-button--duplicate', true ),
};

/**
 * @function onClick
 * @description Handle click event
 * @async
 * @param {Event} e
 */
const onClick = async( e ) => {
	const message = tools.closest( e.target, '.timeline__message' );
	const id = Number( message.dataset.id );

	const response = await duplicateMessage( id );
	if ( response.status === 200 && response.data.redirect ) {
		window.location = response.data.redirect;
	}
};

/**
 * @function bindEvents
 * @description Bind event handlers to listeners
 */
const bindEvents = () => {
	el.duplicateButtons.forEach( button => (
		button.addEventListener( 'click', onClick )
	) );
};

/**
 * @function init
 * @description Kick off this modules functions
 */
const init = () => {
	bindEvents();
};

export default init;
