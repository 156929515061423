/**
 * @module
 * @description JS to power quick tips
 */
import axios from 'axios';
import * as tools from 'utils/tools';

const el = {
	quickTip: tools.getNodes( 'quick-tip' )[ 0 ],
	quickTipDismissForm: tools.getNodes( 'quick-tip__dismiss-form' )[ 0 ],
};

/**
 * @function handleDismissFormSubmit
 * @description Handles form submit via ajax and closes quick tip
 * @param {Event} e
 * @async
 */
const handleDismissFormSubmit = async( e ) => {
	e.preventDefault();
	const endpoint = el.quickTipDismissForm.getAttribute( 'action' );

	if ( endpoint ) {
		el.quickTipDismissForm.removeEventListener( 'submit', handleDismissFormSubmit );
		el.quickTip.parentNode.removeChild( el.quickTip );
		await axios.delete( endpoint );
	}
};

/**
 * @function bindEvents
 * @description Bind event handlers to listeners
 */
const bindEvents = () => {
	el.quickTipDismissForm.addEventListener( 'submit', handleDismissFormSubmit );
};

/**
 * @function init
 * @description Kick off this modules functions
 */
const init = () => {
	if ( el.quickTip ) {
		bindEvents();
	}
};

export default init;
