/**
 * @module
 * @description Search
 */

import * as tools from 'utils/tools';
import { previewShortcut } from './service';
import { onHashChange } from '../ui/title-tracker';

const el = {
	modalPreview: tools.getNodes( 'modal-preview' )[ 0 ],
	modalPreviewContent: tools.getNodes( 'modal-preview-content' )[ 0 ],
	previewButtons: tools.getNodes( 'shortcut__menu-button--preview', true ),
};

const onClick = async( e ) => {
	const shortcut = tools.closest( e.target, '.shortcut' );
	onHashChange( shortcut );
	const shortcutId = Number( shortcut.dataset.id );
	const shortcutType = shortcut.dataset.type;
	const message = await previewShortcut( shortcutId, shortcutType );
	el.modalPreviewContent.innerHTML = message;
};

const bind = () => {
	el.previewButtons.forEach( button => (
		button.addEventListener( 'click', onClick )
	) );
};

/**
 * @function init
 * @description Kick off this modules functions
 */

const init = () => {
	if ( el.modalPreview ) {
		bind();
	}
};

export default init;
