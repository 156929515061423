
/**
 * @module
 * @description Simple Validation plugin
 */

import delegate from 'delegate';
import * as tools from '../utils/tools';

const el = {
	table: tools.getNodes( 'subscribers__table' )[ 0 ],
	checkboxes: tools.getNodes( 'subscribers__table-row-checkbox', true ),
	actions: tools.getNodes( 'subscribers__actions' )[ 0 ],
};

/**
 * @function handleChange
 * @description Handles checkbox change event
 */

const handleChange = () => {
	for ( let i = 0; i < el.checkboxes.length; i++ ) {
		el.checkboxes[ i ];
		if ( el.checkboxes[ i ].checked ) {
			el.actions.classList.add( 'subscribers__actions--show' );
			return;
		}
	}

	el.actions.classList.remove( 'subscribers__actions--show' );
};

/**
 * @function bindEvents
 * @description Bind the events for this module.
 */

const bindEvents = () => {
	delegate( el.table, '[data-js="subscribers__table-row-checkbox"]', 'change', handleChange );
};

/**
 * @function init
 * @description Kick off this modules functions
 */

const validation = () => {
	if ( ! el.table ) {
		return;
	}

	bindEvents();
};

export default validation;
