/**
 * @function sideBarState
 * @description When you move to the next step, update the sidebar
 */
export const next = () => {
	const currentStep = document.querySelector( '.current-step' );

	currentStep.classList.remove( 'current-step' );
	currentStep.classList.add( 'complete' );

	currentStep.nextElementSibling.classList.add( 'current-step' );
};
