/**
 * @module
 * @description Search
 */

import * as tools from 'utils/tools';

const el = {
	modalConfirmation: tools.getNodes( 'modal__confirm-event' )[ 0 ],
};

export const openConfirmationModal = () =>
	$( el.modalConfirmation ).foundation( 'open' );

/**
 * @function init
 * @description Kick off this modules functions
 */

const init = () => {
};

export default init;
