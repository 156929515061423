/* eslint-disable react/jsx-no-bind */

/**
 * @module
 * @description Campaign functionality
 */

import React from 'react';
import * as tools from 'utils/tools';
import { remove } from 'lodash';

import { addMessages } from './service';
import { EVENT_DATA, CAMPAIGNS, STARTER } from 'config/settings';
import { info } from 'utils/notifications';
import Undo from 'Campaign/Notifications/components/Undo';

const el = {
	campaignAdd: tools.getNodes( 'campaign__add' )[ 0 ],
	campaignAddWrapper: tools.getNodes( 'campaign__add__wrapper' )[ 0 ],
	campaignAddButton: tools.getNodes( 'campaign__add-button' )[ 0 ],
	campaignAddButtonGroup: tools.getNodes( 'campaign__add__button__group' )[ 0 ],
	campaignAddNoShortcutsWrapper: tools.getNodes( 'campaign__add__no-shortcuts__wrapper' )[ 0 ],
	campaignAddNoShortcutsDescription: tools.getNodes( 'campaign__add__no-shortcuts__description' )[ 0 ],
	campaignAddNoShortcutsButtonGroup: tools.getNodes( 'campaign__add__no-shortcuts__button__group' )[ 0 ],
	removeShortcutButtons: tools.getNodes( 'modal__content-shortcut-menu-button--remove' ),
	removeSelect: tools.getNodes( 'modal__campaign-remove-select' )[ 0 ],
	removeButton: tools.getNodes( 'modal__campaign-remove-button' )[ 0 ],
};

const updateShortcutTypes = shortcuts => shortcuts.map( s => ( {
	...s,
	type: s.class_type,
	shortcutType: s.type,
} ) );

const getInitialShortcuts = () => {
	const campaign = CAMPAIGNS[ 0 ];
	return campaign && campaign.shortcuts.length
		? campaign.shortcuts
		: STARTER;
};

const state = {
	shortcuts: updateShortcutTypes( getInitialShortcuts() ),
};

const addCampaign = async() => {
	const campaign = CAMPAIGNS[ 0 ];
	const campaign_id = campaign.id || null;
	const { source_id } = EVENT_DATA;

	if ( ! campaign_id && ! source_id ) {
		return;
	}

	const response = await addMessages( source_id, campaign_id, state.shortcuts );
	if ( response.status === 200 && response.data.redirect ) {
		window.location = response.data.redirect;
	}
};

const showEmpty = ( show = true ) => {
	el.campaignAddWrapper.style.display = show ? 'none' : 'block';
	el.campaignAddNoShortcutsWrapper.style.display = show ? 'block' : 'none';
};

const undo = ( shortcut, starter ) => {
	state.shortcuts = starter;
	shortcut.style.display = 'flex';

	if ( state.shortcuts.length ) {
		showEmpty( false );
	}
};

const removeShortcut = ( button ) => {
	const id = Number( button.dataset.shortcutId );
	const starter = state.shortcuts.slice();
	const shortcut = button.closest( '.modal__content-shortcut' );
	shortcut.style.display = 'none';
	const removed = remove( state.shortcuts, ( n ) => n.id === id )[ 0 ];
	info( <Undo undo={ () => undo( shortcut, starter ) } shortcutTitle={ removed.title } /> );

	if ( ! state.shortcuts.length ) {
		showEmpty();
	}
};

const onSelectChange = ( e ) => {
	e.target.value === 'yes'
		? el.removeButton.removeAttribute( 'disabled' )
		: el.removeButton.setAttribute( 'disabled', true );
};

const bindEvents = () => {
	el.campaignAddButton.addEventListener( 'click', addCampaign );
	el.removeShortcutButtons.forEach( button => button.addEventListener( 'click', () => removeShortcut( button ) ) );
	if ( el.removeSelect ) {
		el.removeSelect.addEventListener( 'change', onSelectChange );
	}
};

/**
 * @function init
 * @description Kick off this modules functions
 */

const init = () => {
	if ( ! el.campaignAddButton && ! el.removeSelect ) {
		return;
	}

	bindEvents();
};

export default init;
