import { trigger } from 'utils/events';

export const triggerNotification = ( { type, content, options } ) => trigger( {
	event: 'modern_tribe/toast_notifications_show',
	native: false,
	data: { type, content, options },
} );

export const success = ( content, options = {} ) => triggerNotification( { type: 'success', content, options } );
export const error = ( content, options = {} ) => triggerNotification( { type: 'error', content, options } );
export const info = ( content, options = {} ) => triggerNotification( { type: 'info', content, options } );
