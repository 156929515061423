/**
 * @module
 * @description Campaign Shortcuts
 */

import { on } from 'utils/events';
import * as tools from 'utils/tools';

const el = {
	shortcuts: tools.getNodes( 'page-shortcuts' )[ 0 ],
	shortcutsList: tools.getNodes( 'shortcut-row' )[ 0 ],
};

const showEdit = () => {
	el.shortcutsList.classList.toggle( 'shortcut-row--campaign-edit' );
};

const disableAdd = ( e ) => {
	if ( e.detail.disable ) {
		el.shortcutsList.classList.add( 'shortcut-row--campaign-edit-disable' );
	} else {
		el.shortcutsList.classList.remove( 'shortcut-row--campaign-edit-disable' );
	}
};

const updateListUI = ( e ) => {
	if ( e.detail.open ) {
		el.shortcuts.classList.remove( 'is-campaign-closed' );
		el.shortcuts.classList.add( 'is-campaign-open' );
	} else {
		el.shortcuts.classList.remove( 'is-campaign-open' );
		el.shortcuts.classList.add( 'is-campaign-closed' );
	}
};

const bindEvents = () => {
	on( document, 'modern_tribe/shortcuts_campaign_edit_on', showEdit );
	on( document, 'modern_tribe/shortcuts_campaign_edit_disable', disableAdd );
	on( document, 'modern_tribe/campaign_pane_toggle', updateListUI );
};

/**
 * @function init
 * @description Kick off this modules functions
 */

const init = () => {
	if ( ! el.shortcuts && ! el.shortcutsList ) {
		return;
	}

	bindEvents();
};

export default init;
