/**
 * @module
 * @description Delete Service
 */
import axios from 'axios';
import { SHORTCUTS_ENDPOINT } from 'config/settings';

export async function deleteShortcut( shortcutId ) {
	return await axios.delete( `${ SHORTCUTS_ENDPOINT }/${ shortcutId }` );
}
