/**
 * @module
 * @description Base content module for shared content js and props.
 */

import eventToggle from './eventToggle';
import campaign from './campaign';

/**
 * @function init
 * @description Kick off this modules functions
 */

const init = () => {
	eventToggle();
	campaign();
};

export default init;
