/**
 * @module
 * @description Campaign Service
 */
import axios from 'axios';
import { EVENTS_ENDPOINT } from 'config/settings';

export const addMessages = async( sourceId, campaignId, shortcuts ) => {
	return await axios.post(
		`${ EVENTS_ENDPOINT }/${ sourceId }/campaign/${ campaignId }`,
		{ shortcuts },
	);
};
