/**
 * @module
 * @description Base content module for shared content js and props.
 */

import validation from './validation';
import onboarding from './onboarding';
import settings from './settings';

/**
 * @function init
 * @description Kick off this modules functions
 */

const init = () => {
	validation();
	onboarding();
	settings();
};

export default init;
